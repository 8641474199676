import React from 'react';
import styled from 'styled-components';

import { InputBasic } from '@/component-style/Input';
import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';
import { PSubtitle } from '@/component-style/TextElement';
import TableSalaryOvertimeList from '@/components/Table/Finance/TableSalaryOvertimeList';
import TableSalaryLeaveList from '@/components/Table/Finance/TableSalaryLeaveList';
import TableSalaryExtraPayment from '@/components/Table/TableSalaryExtraPayment';
import TableSalaryLeaveEncashedList from '@/components/Table/Finance/TableSalaryLeaveEncashedList';

const DivSelectWrapper = styled(DivFlexRow)`
  padding: 0;
  margin-bottom: 10px;

  & > div:first-of-type,
  .ant-select {
    width: 100%;
  }
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;


const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const PTexts = styled.p`
  min-width: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.43px;
  color: #8b8b8b;

  span { color: #de2027 }
`;

const ButtonConfirm = styled(ButtonBasic)`
  width: 120px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const PopupSalaryDetail = props => {
  const { detailOfUserId } = props;
  console.log(`PopupSalaryDetail.js JSON.stringify(detailOfUserId)=${JSON.stringify(detailOfUserId)}`);

  return (
    <DivPopupWrapper>
      <DivPopupXLarge>
        <H3Title>薪資明細</H3Title>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>實領薪資</PSubtitle>
            <InputBasic
              disabled = "true"
              value = { detailOfUserId !== undefined && detailOfUserId.totalSalary }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>底薪</PSubtitle>
            <InputBasic
              disabled = "true"
              value = { detailOfUserId !== undefined && detailOfUserId.basicSalary }
            />
          </DivItemWrapper>
           <DivItemWrapper>
            <PSubtitle>勞保</PSubtitle>
            <InputBasic
              disabled = "true"
              value = { detailOfUserId !== undefined && detailOfUserId.insurance.labor }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>健保</PSubtitle>
            <InputBasic
              disabled = "true"
              value = { detailOfUserId !== undefined && detailOfUserId.insurance.health }
            />
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>健保眷屬</PSubtitle>
            <InputBasic
              disabled = "true"
              value = { detailOfUserId !== undefined && detailOfUserId.dependents.healthInsurance }
            />
          </DivItemWrapper>
        </DivFlexRow>
        <TableSalaryOvertimeList
          overtimeDetail = { detailOfUserId === undefined ? [] : detailOfUserId.overtime.details }
        >
        </TableSalaryOvertimeList>
        <br></br>
        <TableSalaryLeaveList
          leaveDetail = { detailOfUserId === undefined ? [] : detailOfUserId.leave.details }
        >
        </TableSalaryLeaveList>
        <br></br>
        <TableSalaryExtraPayment
          extraPayments = { detailOfUserId === undefined ? [] : detailOfUserId.extraPayments.details }
          // extraPaymentTotalCount = { props.extraPaymentTotalCount }
          // extraPaymentCurrentPage = { props.extraPaymentCurrentPage }
          // extraPaymentPageChangedHandler = { (value) => {
          //   props.extraPaymentPageChangedHandler(value);
          // }}
        />
        <br></br>
        <TableSalaryLeaveEncashedList
          encashedLeaveDetails = { detailOfUserId === undefined ? [] : detailOfUserId.encashedLeave.details }
        >
        </TableSalaryLeaveEncashedList>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
        </DivButtonsWrapper>
      </DivPopupXLarge>
    </DivPopupWrapper>
  );
};

export default PopupSalaryDetail;
