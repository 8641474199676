import React, { useState, } from 'react';

import styled from 'styled-components';
import TableWrapper from '@/components/UI/TableWrapper';
import { InputBasic } from '@/component-style/Input';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import { formatTime } from '@/utils/timeUtils';
import { ButtonBasic } from '@/component-style/Button';

import TableSalaryOvertimeList from '@/components/Table/Finance/TableSalaryOvertimeList';
import TableSalaryLeaveList from '@/components/Table/Finance/TableSalaryLeaveList';
import TableSalaryLeaveEncashedList from '@/components/Table/Finance/TableSalaryLeaveEncashedList';

const ButtonSearch = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const PanelMySalary = props => {
  const { salaryConfig, salary, disabledMonth } = props;
  console.log(`PanelMySalary.js salary=${JSON.stringify(salary)}`);
  
  return (
    <TableWrapper title = { props.title }>
      <DivFlexRow>
      <DivItemWrapper>
        <PSubtitle>薪資月份</PSubtitle>
          <DateSelectorTwo
            disabledDate = { disabledMonth }
            value = { salaryConfig.salaryMonth  }
            picker = "month"
            changeHandler = { value => {
              props.updateSearchSalaryHandler(
                'salaryMonth',
                formatTime(value, 'YYYY-MM')
              );
            }}
          />
          &nbsp;&nbsp;
          <PSubtitle>於 { salaryConfig.payMonth } 月發放</PSubtitle>
        </DivItemWrapper>
        <DivItemWrapper>
          <ButtonSearch
            onClick = { () => { props.searchSalaryHandler(); } }
          >搜尋</ButtonSearch>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>底薪</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { salary === undefined ? "" : salary.basicSalary}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>勞保保費</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.insurance.labor }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
       <DivItemWrapper>
          <PSubtitle>健保保費</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.insurance.health }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>請假扣薪</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { salary === undefined ? "" : (salary.leave === undefined ? "" : salary.leave.salary)}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>加班薪資</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { salary === undefined ? "" : salary.overtimePay}
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>勞退自提</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.pensionDetails.selfPayPension }
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>休假轉換薪資</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.encashedLeave.salary }
          />
        </DivItemWrapper>
        <DivItemWrapper>
          <PSubtitle>實領薪資</PSubtitle>
          <InputBasic
            disabled = { props.disabled }
            value = { salary === undefined ? "" : salary.totalSalary}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <br></br>
      <TableSalaryOvertimeList
        overtimeDetail = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.overtime.details }
      >
      </TableSalaryOvertimeList>
      <br></br>
      <TableSalaryLeaveList
        leaveDetail = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.leave.details }
      >
      </TableSalaryLeaveList>
      <br></br>
      <TableSalaryLeaveEncashedList
        encashedLeaveDetails = { (salary === undefined || JSON.stringify(salary) === "{}") ? [] : salary.encashedLeave.details }
      >
      </TableSalaryLeaveEncashedList>
    </TableWrapper>
  );
};

export default PanelMySalary;
