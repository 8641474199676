import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getAzureLoginUrl } from '@/api/authService';
import { LOGIN, AZURE_LOGIN_START } from '@/store/actions/loginAction';

import LoginAside from '@/components/UI/LoginAside';
import LoginPanel from '@/components/LoginPanel';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import { recaptchaSiteKey } from '@/config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const DivLogin = styled.div`
  display: flex;
`;

const Login = props => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.login.loading);
  const errorMsg = useSelector(state => state.login.errorMsg);
  const showError = useSelector(state => state.login.loginFail);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const loginHandler = reCaptchaToken => {
    dispatch(LOGIN({ account, password, reCaptchaToken }));
  };

  const loginByAzureHandler = () => {
    dispatch(AZURE_LOGIN_START());
    window.location.href = getAzureLoginUrl();
  }

  return (
    // <GoogleReCaptchaProvider reCaptchaKey = { recaptchaSiteKey }>
      <DivLogin>
        <LoginAside/>
        <LoginPanel
          errorMsg = { errorMsg }
          showError = { showError }
          setAccount = { setAccount }
          setPassword = { setPassword }
          login = { loginHandler }
          loginByAzureHandler = { loginByAzureHandler }
        />
      </DivLogin>
      // { loading && <PopupLoading/> }
    // </GoogleReCaptchaProvider>
  );
};

export default Login;
