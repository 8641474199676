import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { TdItem, TableRow } from '@/component-style/Table';
// import { ButtonGoDetail } from '@/component-style/Button';

import {
  // getOptionDesc,
  // getOvertimeApprovementStatus,
} from '@/utils/textUtils';

import {getHourlySalary,} from '@/utils/mathUtils';

import {
  // diffTime,
  // diffTimeWithFloat,
  formatTime,
  getOvertimeWeightedHour,
  diffTimeSubtractLunchBreak,
} from '@/utils/timeUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1280px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { overtimeRecord } = props;
  const { startTime, endTime } = overtimeRecord;
  // console.log("overtimerRecord:" + JSON.stringify(overtimeRecord));
  const overTimeRecordsOfUser = props.overtimeRecords.filter(function(value, index, arr){ 
    return value.date === overtimeRecord.date;
  });
  
  return (
    <TrWrapper>
      <Td120>{ overtimeRecord.username }</Td120>
      <Td120>{ overtimeRecord.date }</Td120>
      <Td240>
        {
          // `${formatTime(startTime, 'MM/DD HH:mm')} ~ ${formatTime(endTime, 'MM/DD HH:mm')}`
          `${moment.parseZone(overtimeRecord.startTimeLocal).format('MM/DD HH:mm')} ~ ${moment.parseZone(overtimeRecord.endTimeLocal).format('MM/DD HH:mm')}`
        }
      </Td240>
      <Td160>{ diffTimeSubtractLunchBreak(startTime, endTime, 'hours') }</Td160>
      <Td160>{ getOvertimeWeightedHour(props.workingHoursParamsConfig, overtimeRecord.overtimeDateType, overTimeRecordsOfUser, overtimeRecord, /*diffTimeSubtractLunchBreak(startTime, endTime, 'hours')*/).toFixed(2) }</Td160>
      <Td120>{ getHourlySalary(overtimeRecord.basicSalary) }</Td120>
      <Td120>{ Math.round(getOvertimeWeightedHour(props.workingHoursParamsConfig, overtimeRecord.overtimeDateType, overTimeRecordsOfUser, overtimeRecord) * getHourlySalary(overtimeRecord.basicSalary)) }</Td120>
      {/* <Td120>{ Math.round(getOvertimeWeightedHour(props.workingHoursParamsConfig, overtimeRecord.overtimeDateType, overTimeRecordsOfUser, diffTimeSubtractLunchBreak(startTime, endTime, 'hours')) * getHourlySalary(overtimeRecord.basicSalary) * 100) / 100 }</Td120> */}
      <Td240>{ overtimeRecord.crossTeam === true ? "是" : "否" }</Td240>
    </TrWrapper>
  );
};

export default Item;
