import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
// import { getOptionDesc } from '@/utils/textUtils';
import { decimalCommaConverter } from '@/utils/textUtils';
import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 760px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Item = props => {
  const { paycheckStructure } = props;
  console.log(`TableWageStructure Item.js JSON.stringify(paycheckStructure)=${JSON.stringify(paycheckStructure)}`);
  // const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  // const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);

  // const director = getOptionDesc(directorOptions, user.director);
  // const department = getOptionDesc(departmentOptions, user.department);
  const strPreviousMonth = moment().subtract(1, 'M').format("YYYY-MM");
  const strCurrentMonth = moment().format("YYYY-MM");
  const strApplyDate = moment(paycheckStructure.applyDate).format("YYYY-MM");
  console.log(`TableWageStructure Item.js strPreviousMonth=${strPreviousMonth}&strApplyDate=${strApplyDate}`);
  var paycheckStructureEditable = true;
  // if (strPreviousMonth === strApplyDate && moment().format("D") < 5) {
  //   paycheckStructureEditable = true;
  // }
  // if (strApplyDate === strCurrentMonth || moment(paycheckStructure.applyDate) > moment()) {
  //   paycheckStructureEditable = true;
  // }

  function getHealthInsuranceOfDependents(dependents) {
    var total = 0;
    for (var i = 0; i < dependents.length; i++) {
      total = total + dependents[i].healthInsurance.amount;
    }
    return total;
  }

  return (
    <TrWrapper>
      <Td160>{ moment(paycheckStructure.applyDate).format("YYYY-MM") }</Td160>
      <Td160>{ decimalCommaConverter(paycheckStructure.basicSalary) }</Td160>
      <Td160>{ decimalCommaConverter(paycheckStructure.insurance.grade) }</Td160>
      <Td160>{ decimalCommaConverter(paycheckStructure.insurance.laborPay) }</Td160>
      <Td160>{ decimalCommaConverter(paycheckStructure.insurance.healthPay) }</Td160>
      <Td160>{ paycheckStructure.dependents.length + "(" + getHealthInsuranceOfDependents(paycheckStructure.dependents) + ")"}</Td160>
      <Td160>{ paycheckStructure.selfPayPensionPercentage + "%" }</Td160>
      <Td80>
        { paycheckStructureEditable &&
          <ButtonGoDetail
            onClick = { () => { 
              props.viewPaycheckStructureHandler(paycheckStructure.id) 
            } }
          >...
          </ButtonGoDetail>
        }
      </Td80>
    </TrWrapper>
  );
};

export default Item;
