import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from "react-router-dom"

import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetScheduleTimeConfig } from '@/api/attendanceService';

import {
  putNewWorkingHours,
  postToGetWorkingHours,
} from '@/api/attendanceService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelOvertimeDetail from '@/components/Panel/PanelOvertimeDetail';
import TableOvertimeList from '@/components/Table/Attendance/TableOvertimeList';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { overtimeConfig } from '@/constant/dataConfig/attendanceModule';
import {
  diffTime,
  diffTimeSubtractLunchBreak,
  diffTimeWithFloat,
  toUTCTime,
  formatTime,
  getEndOfUnit,
  getStartOfUnit,
  getOvertimeDateType,
  canWorkOvertimeOnSaturdayOrSunday,
} from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const OvertimeApplication = () => {
  const history = useHistory()
  // const { workingDate } = useParams();
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const previousSearchedWorkingDate = params.get('workingDate'); // workingDate
  // console.log("OvertimeApplication previousSearchedWorkingDate:" + previousSearchedWorkingDate);
  
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const username = useSelector(state => state.login.user);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [overtime, setOvertime] = useState({ ...overtimeConfig });
  // setOvertime({
  //   ...overtimeConfig,
  //   workingDate: previousSearchedWorkingDate,
  // });
  // console.log("overtimeApplication.js overtime" + JSON.stringify(overtime));
  const [overtimeParams, setOvertimeParam] = useState({
    overTimeLimit: 0,
    freeTimeLimit: 0,
    extraPayLimit: 0,
  });

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showAlertPopupOverlapped, setShowAlertPopupOverlapped] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showAlertOnlyWorkOvertimeOnSaturdayOrSunday, setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday] = useState(false);

  const overtimeMinutes = diffTimeSubtractLunchBreak(overtime.startTimeLocal, overtime.endTimeLocal, 'minutes') || 0;

  // const [overtimeDateType, setOvertimeDateType] = useState("workdays");
  var overtimeDateType = "workdays";

  const updateOvertimeHandler = (key, value) => {
    // console.log("updateOvertimeHandler key:" + key + " value:" + value);
    const updateObj = { ...overtime };
    updateObj[key] = value;
    setOvertime({...updateObj});
  };

  const typeChangeHandler = value => {
    setOvertime(prevState => ({
      ...prevState,
      typeID: value,
      tag: '',
    }));
  };

  const closeSuccessPopupHandler = () => {
    // console.log("closeSuccessPopupHandler");
    setShowSaveSuccessPopup(false);
    setOvertime(prevState => ({
      ...overtimeConfig,
      workingDate: prevState.workingDate,
    }));
    fetchOvertimes();
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    // fetchOvertimes(overtime.workingDate, page);
  };

  const clearHandler = () => {
    setOvertime({
      ...overtimeConfig,
      workingDate: null,
    });
    setOvertimeRecords([]);
    setTotalCount(0);
  };

  const workingDateChangeHanlder = value => {
    const params = new URLSearchParams()
    if (value !== null) {
      // console.log("overtimeApplication.js workingDateChangeHanlder date:" + value);
      params.append("workingDate", formatTime(value, 'YYYY-MM-DD'))
      history.push({search: params.toString()})
    }
    // console.log("workingDateChangeHanlder overtime:" + JSON.stringify(overtime));
    setOvertime({
      ...overtimeConfig,
      workingDate: value,
      userID: userID,
    });
    setOvertimeRecords([]);
    setCurrentPage(1);
    // value !== null && fetchOvertimes(value, 1);
  };

  //Modified by Ian
  /*const isOvertimeAlert = mins => mins > overtimeParams.overTimeLimit * 60
    || (overtime.overtimeType === 'freeTime' && mins > overtimeParams.freeTimeLimit * 60)
    || (overtime.overtimeType === 'extraPay' && mins > overtimeParams.extraPayLimit * 60);*/
  const isOvertimeAlert = (overtimeDateType, mins) => 
  {
    // console.log("isOvertimeAlert overtimeParams:" + JSON.stringify(overtimeParams));
    // console.log("isOvertimeAlert overtimeDateType:" + overtimeDateType);
    var boolOvertimeHourOverLimit = false;
    let thisDayOvertimerWorkingHours = diffTimeWithFloat(overtime.startTimeLocal, overtime.endTimeLocal, 'hours');
    for (const objOvertimeRecord of overtimeRecords) {
      // console.log("isOvertimeAlert objOvertimeRecord.startTimeLocal" + objOvertimeRecord.startTimeLocal);
      thisDayOvertimerWorkingHours += diffTimeWithFloat(objOvertimeRecord.startTimeLocal, objOvertimeRecord.endTimeLocal, 'hours')
    }
    // console.log("isOvertimeAlert thisDayOvertimerWorkingHours:" + thisDayOvertimerWorkingHours);
    if (overtimeDateType === "workdays") {
      if (thisDayOvertimerWorkingHours > overtimeParams.overTimeLimit) {
        boolOvertimeHourOverLimit = true;
      }
    } else {
      if (thisDayOvertimerWorkingHours > 12) {
        boolOvertimeHourOverLimit = true;
      }
    }
    return boolOvertimeHourOverLimit;
  };

  const isOverTimesOverlapped = () =>
  {
    let overlapped = false;
    let overtimeArray = [];
    overtimeArray.push({startTimeLocal:overtime.startTimeLocal, endTimeLocal: overtime.endTimeLocal});
    for (const objOvertimeRecord of overtimeRecords) {
      overtimeArray.push({startTimeLocal:objOvertimeRecord.startTimeLocal, endTimeLocal: objOvertimeRecord.endTimeLocal});
    }
    overtimeArray.sort((a,b)=>new Date(a.startTimeLocal) - new Date(b.startTimeLocal));
    if (overtimeArray.length >= 2) {
      for (var i = 0; i < overtimeArray.length - 1; i++) {
        if (new Date(overtimeArray[i+1].startTimeLocal) >= new Date(overtimeArray[i].startTimeLocal) && new Date(overtimeArray[i+1].startTimeLocal) < new Date(overtimeArray[i].endTimeLocal)) {
          /*console.log("isOverTimesOverlapped overtimeArray:" + overtimeArray[i+1].startTimeLocal);
          console.log("isOverTimesOverlapped overtimeArray:" + overtimeArray[i].startTimeLocal);
          console.log("isOverTimesOverlapped overtimeArray:" + overtimeArray[i+1].endTimeLocal);*/
          overlapped = true;
          break;
        }
      }
    }
    //console.log("isOverTimesOverlapped overtimeArray:" + JSON.stringify(overtimeArray));
    //console.log("isOverTimesOverlapped overlapped" + overlapped);
    return overlapped;
  }
  //Modified by Ian

  const submitHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate } = overtime;

    overtime.userID = userID;    
    overtimeDateType = await getOvertimeDateType(overtime);

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const currentHours = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);
      let boolCanWorkOvertimeOnSaturdayOrSunday = await canWorkOvertimeOnSaturdayOrSunday(overtime);
      console.log(`submitHandler() boolCanWorkOvertimeOnSaturdayOrSunday=${boolCanWorkOvertimeOnSaturdayOrSunday}`);

      !boolCanWorkOvertimeOnSaturdayOrSunday ? setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday(true) : 
        isOverTimesOverlapped() 
          ? setShowAlertPopupOverlapped(true)
          : isOvertimeAlert(overtimeDateType, overtimeMinutes + currentHours)
              ? setShowAlertPopup(true)
              : submitNewOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate } = overtime;

    // console.log("saveHandler userID:" + userID);
    overtime.userID = userID;
    // console.log("workingDateChangeHanlder overtime:" + JSON.stringify(overtime));
    
    // let resultOfOvertimeDateType = await getOvertimeDateType(overtime);
    // setOvertimeDateType(resultOfOvertimeDateType);
    overtimeDateType = await getOvertimeDateType(overtime);
    // console.log("saveNewOvertime overtimeDateType:" + overtimeDateType);

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const currentHours = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);

      console.log("saveHandler overtimeMinutes:" + overtimeMinutes);
      let boolCanWorkOvertimeOnSaturdayOrSunday = await canWorkOvertimeOnSaturdayOrSunday(overtime);
      // console.log("saveHandler boolCanWorkOvertimeOnSaturdayOrSunday:" + boolCanWorkOvertimeOnSaturdayOrSunday);
      !boolCanWorkOvertimeOnSaturdayOrSunday ? setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday(true) :
        isOverTimesOverlapped()  
          ? setShowAlertPopupOverlapped(true)
          : isOvertimeAlert(overtimeDateType, overtimeMinutes + currentHours)
              ? setShowAlertPopup(true)
              : saveNewOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveNewOvertime = async () => {
    setShowLoadingPopup(true);

    // let overtimeDateType = "";
    // console.log("saveNewOvertime userID:" + userID);
    // overtime.userID = userID;
    // console.log("workingDateChangeHanlder overtime:" + JSON.stringify(overtime));
    
    let overtimeDateType = await getOvertimeDateType(overtime);
    // console.log("saveNewOvertime overtimeDateType:" + overtimeDateType);

    try {
      await putNewWorkingHours({
        ...overtime,
        userID,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
        overtimeDateType : overtimeDateType,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      console.log(err);
    }
  };

  const submitNewOvertime = async () => {
    let overtimeDateType = await getOvertimeDateType(overtime);
    try {
      await putNewWorkingHours({
        ...overtime,
        userID,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        submitDate: formatTime(Date.now(), 'YYYY-MM-DD HH:mm:ss'),
        approvementStatus: 'unapproved',
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
        overtimeDateType : overtimeDateType,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      console.log(err);
    }
  };

  const fetchOvertimeParams = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetScheduleTimeConfig();
      // console.log("fetchOvertimeParams data:" + JSON.stringify(data));
      // console.log("fetchOvertimeParams overtimeParams:" + JSON.stringify(overtimeParams));
      const { overTimeLimit, freeTimeLimit, extraPayLimit } = data;

      setOvertimeParam(prevState => ({
        ...prevState,
        overTimeLimit,
        freeTimeLimit,
        extraPayLimit,
      }));
      // console.log("fetchOvertimeParams overtimeParams:" + JSON.stringify(overtimeParams));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };
  
  const fetchOvertimes = async (date = overtime.workingDate, page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      // console.log("overtimeApplication.js fetchOvertimes date:" + date);
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(date, 'YYYY-MM-DD'),
        endDate: formatTime(date, 'YYYY-MM-DD'),
        amount: 10,
        page,
      });

      const { workingHours, totalCount } = data;
      const overtimeRecords = [];

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = projectInfo.data;
        overtimeRecords.push({
          ...workingHours[i],
          username,
          projectCode,
          projectName,
        });
      }

      setOvertimeRecords([ ...overtimeRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initOvertimeParams = useCallback(
    fetchOvertimeParams, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS({ enable: true, enableTag: true }));
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initOvertimeParams();
  }, [initOptions, initOvertimeParams]);

  // const retrieveOvertime = useCallback(
  //   fetchOvertimes
  // , []);

  const handlePreviousSearch = useCallback(() => {
    if (previousSearchedWorkingDate !== undefined && previousSearchedWorkingDate !== "") {
      // console.log("handlePreviousSearch" + previousSearchedWorkingDate);
      setOvertime({
        ...overtimeConfig,
        workingDate: previousSearchedWorkingDate,
      });
      setOvertimeRecords([]);
      setCurrentPage(1);
    }
  }, [previousSearchedWorkingDate]);
  
  useEffect(() => {
    handlePreviousSearch();
  }, [handlePreviousSearch]);

  // useEffect(() => {
  //   console.log("useEffect :" + overtime.workingDate);
  //   retrieveOvertime();
  // }, [retrieveOvertime, overtime.workingDate]);

  useEffect(() => {
    async function fetchOvertimesByIan() {
      // console.log("fetchOvertimesByIan useEffect fetchOvertimesByIan :" + overtime.workingDate);
      // console.log("fetchOvertimesByIan useEffect :" + userID);
      setShowLoadingPopup(true);
      try {
        const { data } = await postToGetWorkingHours({
          isOverTime: true,
          userIDs: [userID],
          startDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
          endDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
          amount: 10,
          page: currentPage,
        });
        // console.log("fetchOvertimesByIan useEffect :" + JSON.stringify(data));
        const { workingHours, totalCount } = data;
        const overtimeRecords = [];

        for (let i = 0; i < workingHours.length; i += 1 ) {
          const { projectID } = workingHours[i];
          const projectInfo = await postToGetProjectDetail(projectID);
          const { projectCode, projectName } = projectInfo.data;
          overtimeRecords.push({
            ...workingHours[i],
            username,
            projectCode,
            projectName,
          });
        }

        setOvertimeRecords([ ...overtimeRecords ]);
        setTotalCount(totalCount || 0);
      } catch (err) { console.log(err) }

      setShowLoadingPopup(false);
    }

    if (overtime.workingDate !== null) {
      fetchOvertimesByIan();
    }
    // const waitForOtStatus = async () => {
    //   const otStatus = await getOtStatus(overtime)
    //   // do something else here after firstFunction completes
    //   console.log("fetchOvertimesByIan useEffect otStatus.overtimeDateType :" + JSON.stringify(otStatus));
    //   // let overtimeDateType = otStatus.overtimeDateType;
    //   updateOvertimeHandler("overtimeDateType", otStatus?.overtimeDateType);
    // }
    // waitForOtStatus();
    // let otStatus = getOtStatus(overtime);
    // console.log("fetchOvertimesByIan useEffect otStatus.overtimeDateType :" + JSON.stringify(otStatus));
    // updateOvertimeHandler("overtimeDateType", "workdays");
    // setOvertime(prevState => ({
    //   ...prevState,
    //   overtimeDateType: otStatus.overtimeDateType,
    // }));
  }, [username, userID, overtime.workingDate, currentPage]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelOvertimeDetail
          title = "加班申請"
          overtime = { overtime }
          projectIDOptions = { projectIDOptions }
          workingHoursTypeOptions = { workingHoursTypeOptions }
          workingHoursTagDictionary = { workingHoursTagDictionary }
          typeChangeHandler = { typeChangeHandler }
          updateOvertimeHandler = { updateOvertimeHandler }
          workingDateChangeHanlder = { workingDateChangeHanlder }
        >
          <DivFlexRow>
            <DivButtonsWrapper>
              <ButtonSave
                onClick = { saveHandler }
                disabled = {  overtime.startTimeLocal === null
                  || overtime.endTimeLocal === null
                  || overtimeMinutes <= 0
                }
              >儲存</ButtonSave>
              <ButtonSave
                onClick = { submitHandler }
                disabled = {  overtime.startTimeLocal === null
                  || overtime.endTimeLocal === null
                  || overtimeMinutes <= 0
                }
              >送審</ButtonSave>
              <ButtonClear
                onClick = { clearHandler }
              >清除欄位</ButtonClear>
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelOvertimeDetail>
      </DivPanelContainer>
      <TableOvertimeList
        overtimeRecords = { overtimeRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "新增成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "超過工作日期工時上限，無法新增工時"
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showAlertPopupOverlapped &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "加班工時重覆，無法新增工時"
            confirmHandler = { () => { setShowAlertPopupOverlapped(false) } }
          />
      }
      {
        showAlertOnlyWorkOvertimeOnSaturdayOrSunday &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "除非遇到天災、事變或突發事件需出勤工作，其他原因不得申請例假日加班。"
            confirmHandler = { () => { setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default OvertimeApplication;
