import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { 
  postToGetUploadFiles,
  postToGetUploadFilesWithArrayOfProjects
} from '@/api/storageService';
import { postToGetCompanyType } from '@/api/sysConfigService';

import {
  diffTime,
} from '@/utils/timeUtils';

import {
  // postToGetProjectList,
  postToGetProjectDetail,
  postToGetProjectDetailWithArrayOfProjects,
  postToGetDashboardProjectList,
} from '@/api/projectService';

import {
  getApprovedLeavesByDate,
} from '@/api/financeService';

import moment from 'moment';

import {
  postToGetQuotations,
  postToGetQuotationsWithArrayOfProjectIDs,
  postToGetExpectedCost,
  postToGetVouchersTotal,
  postToGetVouchersTotalWithArrayOfProjectIDs,
  postToGetExpenseParamsConfig
} from '@/api/expenseService';

import {
  postToGetExpectedIncome,
  postToGetProjectQuotations,
  postToGetProjectQuotationsWithArrayOfProjects,
  postToGetProjectInvoicesDetail,
  postToGetProjectInvoicesDetailWithArrayOfProjects,
} from '@/api/financeService';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { postToGetProjectTodos } from '@/api/projectTodoService';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_TODO_TYPE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import TableProjects from '@/components/Table/TableProjects';
import TableApprovedLeavedApplications from '@/components/Table/TableApprovedLeavedApplications';
// import TableChart from '@/components/Table/Project/TableChart';
// import TableSchedules from '@/components/Table/TableSchedules';

import { postToGetExpenses } from '@/api/expenseService';
import TableExpenseFormList from '@/components/Table/Expense/TableExpenseFormList';

import { integerConverter } from '@/utils/textUtils';
import {
  // diffTime,
  formatTime,
} from '@/utils/timeUtils';

// import { Line } from '@ant-design/charts';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import {
  useSort
} from '@/hooks/sort';

const DivTableWrapper = styled.div`
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const amount = 10;

const Dashboard = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const memberID = useSelector(state => state.login.token);
  const userID = useSelector(state => state.login.token);
  const showProject = useSelector(state => state.login.showProject);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [projectCurrentPage, setProjectCurrentPage] = useState(1);
  // const [schedules, setSchedules] = useState([]);
  // const [scheduleCount, setScheduleCount] = useState(0);
  const [scheduleCurrentPage, setScheduleCurrentPage] = useState(1);
  const [displayMode, setDisplayMode] = useState('involved');
  const [projectCodeFilter, setProjectCodeFilter] = useState('');

  // const [chartMode, setChartMode] = useState('income');
  const [chartCompany, setChartCompany] = useState('');
  const [expectedCost, setExpectedCost] = useState({});
  const [expectedIncome, setExpectedIncome] = useState({});

  const [alertText, setAlertText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  var responseCount = 0;
  var maxCountForPopupLoading = 1;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [expenses, setExpenses] = useState([]);

  const { tableSortConfig, tableSortHandler, getTableSortIcon } = useSort("companyType");
  
  const [approvedApplications, setApprovedApplications] = useState([]);
  // const chartData = chartMode === 'income'
  //   ? expectedIncome[chartCompany]
  //   : expectedCost[chartCompany];

  const goProjectDetailHandler = projectID => {
    history.push(`/Project/ProjectDetail/${projectID}`);
    // const project = projects.filter(project => project.projectID === projectID).pop();
    // const { noPO, notQuoted, unlocked } = project;
    // const isProjectLocked = !unlocked && (noPO || notQuoted);

    // if (isProjectLocked) {
    //   const alertText = `
    //     專案已被鎖定，
    //     ${ noPO ? '無PO資料，' : '' }
    //     ${ notQuoted ? '無報價資料，' : '' }
    //     請專案總監或總經理解鎖。
    //   `;
    //   setAlertText(alertText);
    //   setShowAlertPopup(true);
    // } else {
    //   history.push(`/Project/ProjectDetail/${projectID}`);
    // }
  };

  // const goScheduleDetailHandler = async (projectID, todoID) => {
  //   setShowLoadingPopup(true);

  //   try {
  //     const projectData = await postToGetProjectDetail(projectID);
  //     const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
  //     const projectQuotations = await postToGetProjectQuotations({
  //       projectID, amount: 1, page: 1,
  //     });

  //     const unlocked = projectData.data.projectUnlock !== null
  //       && projectData.data.projectUnlock !== undefined
  //       && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

  //     const notQuoted = projectQuotations.data.totalCount === undefined
  //       && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 8;

  //     const noPO = projectFinanceFiles.data.length === 0
  //       && diffTime(projectData.data.actionTimeEnd, Date.now(), 'days') < 4;

  //     const isProjectLocked = !unlocked && (noPO || notQuoted);

  //     setShowLoadingPopup(false);

  //     history.push(`/Project/ScheduleDetail/${todoID}`);
  //     // if (isProjectLocked) {
  //     //   const alertText = `
  //     //     ${ noPO ? '無 PO 資料，' : '' }
  //     //     ${ notQuoted ? '無報價資料，' : '' }
  //     //     專案已被鎖定，請專案總監或總經理解鎖。
  //     //   `;
  //     //   setAlertText(alertText);
  //     //   setShowAlertPopup(true);
  //     // } else {
  //     //   history.push(`/Project/ScheduleDetail/${todoID}`);
  //     // }
  //   } catch (err) {
  //     console.log(err);
  //     setShowLoadingPopup(false);
  //   }
  // };

  const updateDisplayModeHandler = type => {
    setDisplayMode(type);
    searchHandler(type);
  };

  const projectCodeFilterChangeHandler = filter => {
    setProjectCodeFilter(filter);
  };

  const setProjectPageHandler = page => {
    setProjectCurrentPage(page);
    maxCountForPopupLoading = 1;
    updateProjectList(page);
  };

  const searchHandler = type => {
    setProjectCurrentPage(1);
    updateProjectList(1, type);
  };

  // const setSchedulePageHandler = page => {
  //   setScheduleCurrentPage(page);
  //   updateScheduleList(page);
  // };

  // const updateProjectList = async (page = projectCurrentPage, type = displayMode) => {
  //   setShowLoadingPopup(true);
  //   console.log("dashboard.js updateProjectList start");
  //   let agentDifference = 0;

  //   try {
  //     const { data } = await postToGetProjectList({
  //       page,
  //       amount,
  //       projectCode: projectCodeFilter || null,
  //       memberID: type === 'all' ? null : memberID,
  //     });
  //     const { projects, totalCount } = data;
  //     projects.sort((a, b) => a.projectCode > b.projectCode ? 1 : -1);

  //     const expenseParamsDetail = await postToGetExpenseParamsConfig();
  //     agentDifference = expenseParamsDetail.data.agentDifference || 0;

  //     for (let i = 0; i < projects.length; i += 1) {
  //       const project = projects[i];
  //       const { projectID } = project;
  //       const projectData = await postToGetProjectDetail(projectID);
  //       const projectInvoices = await postToGetProjectInvoicesDetail(projectID);
  //       const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
  //       const projectQuotations = await postToGetProjectQuotations({
  //         projectID,
  //         amount: 1,
  //         page: 1,
  //       });

  //       const quotationsDetail = await postToGetQuotations({ projectID });
  //       const voucherTotalDetail = await postToGetVouchersTotal({
  //         projectID,
  //         object: [],
  //         item: 'amount',
  //         approvalStatus: [
  //           'projectManagerVerify',
  //           'projectDirectorVerify',
  //           'financeVerify',
  //           'generalManagerVerifying',
  //           'generalManagerApproved',
  //         ],
  //       });

  //       const quotationPriceTotal = quotationsDetail.data
  //         .map(({ price }) => integerConverter(price))
  //         .reduce((acc, cur) => acc + cur, 0);
          
  //       const voucherPriceTotal = voucherTotalDetail.data.total || 0;

  //       const oopQuotations = projectQuotations.data.quotations
  //         .filter(quotation => quotation.type === 'oop');

  //       const oopPriceTotal = oopQuotations
  //         .map(({ price }) => integerConverter(price || 0))
  //         .reduce((acc, cur) => acc + cur, 0);

  //       const { invoices } = projectInvoices.data;
  //       const isInvoiced = invoices.length > 0;
  //       const isPaid = invoices
  //         .some(quotation => quotation.paymentDate !== undefined);

  //       const predictDates = invoices
  //         .filter(({ predictDate }) => predictDate !== undefined)
  //         .map(({ predictDate }) => predictDate);

  //       const predictDate = predictDates.length > 0
  //         ? predictDates.shift()
  //         : null;

  //       let financeStatus = 'success';

  //       const isFinanceFail = quotationPriceTotal - oopPriceTotal > agentDifference
  //         || voucherPriceTotal - oopPriceTotal > agentDifference;
  
  //       const isFinanceWarning = quotationPriceTotal > oopPriceTotal
  //         || voucherPriceTotal > oopPriceTotal;
  
  //       if (isFinanceWarning) { financeStatus = 'warning' }
  //       if (isFinanceFail) { financeStatus = 'fail' }

  //       const unlocked = projectData.data.projectUnlock !== null
  //         && projectData.data.projectUnlock !== undefined
  //         && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;

  //       const notQuoted = projectQuotations.data.totalCount === undefined
  //         && diffTime(Date.now(), projectData.data.actionTimeEnd, 'days') < 8;

  //       const noPO = projectFinanceFiles.data.length === 0
  //         && diffTime(Date.now(), projectData.data.actionTimeEnd, 'days') < 4;

  //       project.noPO = noPO;
  //       project.isPaid = isPaid;
  //       project.unlocked = unlocked;
  //       project.notQuoted = notQuoted;
  //       project.isInvoiced = isInvoiced;
  //       project.financeStatus = financeStatus;
  //       project.predictDate = isInvoiced ? predictDate : null;
  //       project.actionTimeEnd = projectData.data.actionTimeEnd;
  //       project.isVerified = projectFinanceFiles.data.length > 0;
  //       project.isQuoted = projectQuotations.data.totalCount
  //         && projectQuotations.data.totalCount > 0;
  //     }

  //     setProjects([...projects]);
  //     setProjectCount(totalCount);
  //     console.log("dashboard.js " + JSON.stringify(projects));
  //   } catch (err) { console.log(err) }

  //   setShowLoadingPopup(false);
  // };

  // const updateProjectList = async (page = projectCurrentPage, type = displayMode) => {
  //   setShowLoadingPopup(true);
  //   console.log("dashboard.js updateProjectList start");
  //   let agentDifference = 0;

  //   try {
  //     const { dashboardData } = await postToGetDashboardProjectList({
  //       page,
  //       amount,
  //       projectCode: projectCodeFilter || null,
  //       memberID: type === 'all' ? null : memberID,
  //     });
  //     // console.log("dashboard.js " + JSON.stringify(dashboardData));
  //     let projects = [];
  //     let totalCount = 0;
  //     if (dashboardData === undefined) {
  //       console.log("dashboard.js dashboard is undefined");
  //     } else {
  //       console.log("dashboard.js dashboard is not undefined");
  //       projects = dashboardData.projects;
  //       totalCount = dashboardData.totalCount;
  //     }
      

  //     setProjects([...projects]);
  //     setProjectCount(totalCount);
  //     console.log("dashboard.js " + JSON.stringify(projects));
  //     // setProjects([...dashboardProjects]);
  //     // setProjectCount(dashboardTotalCount);
  //   } catch (err) { console.log(err) }

  //   setShowLoadingPopup(false);
  // };

  const updateProjectList = async (page = projectCurrentPage, type = displayMode) => {
    if (responseCount === 0) {
      setShowLoadingPopup(true);
    }
    console.log("dashboard.js updateProjectList start responseCount:", responseCount);
    responseCount += 1;
    console.log("dashboard.js updateProjectList responseCount:", responseCount);
    let agentDifference = 0;
    
    try {
      const { sortField, sortOrder } = tableSortConfig;
      const { data } = await postToGetDashboardProjectList({
        page,
        amount,
        projectCode: projectCodeFilter || null,
        // memberID: type === 'all' ? null : memberID,
        excludeDoneProject:true,
        sortField: sortField,
        sortOrder: sortOrder,
      });
      const { totalCount } = data;
      const projects = [];
      const tempProjects = data.projects;
      for (let i = 0; i < tempProjects.length; i += 1) {
        var singleProject = {};
        singleProject.actionTimeStart = tempProjects[i].projectDetail.actionTimeStart;
        singleProject.financeStatus = 'success';
        const quotationPriceTotal = tempProjects[i].expenseQuotationPrices
          .map(({ price }) => integerConverter(price))
          .reduce((acc, cur) => acc + cur, 0);
        singleProject.companyType = tempProjects[i].projectDetail.companyType;
        singleProject.projectCode = tempProjects[i].projectDetail.projectCode;
        singleProject.projectName = tempProjects[i].projectDetail.projectName;
        singleProject.projectID = tempProjects[i].projectDetail.projectID;
        // console.log("updateProjectList tempProjects " + tempProjects[i].projectDetail.projectID);
        projects.push(singleProject);
      }
      projects.sort((a, b) => a.projectCode > b.projectCode ? 1 : -1);

      const expenseParamsDetail = await postToGetExpenseParamsConfig();
      agentDifference = expenseParamsDetail.data.agentDifference || 0;

      const projectIDs = projects.map(element => element.projectID);      
      const projectsData = await postToGetProjectDetailWithArrayOfProjects(projectIDs);
      const projectsInvoicesDetail = await postToGetProjectInvoicesDetailWithArrayOfProjects(projectIDs);

      const arrayOfObject = projects.map(element => ({ objectType: "projectFinance", objectID: element.projectID }) );
      const arrayOfProjectFiles = await postToGetUploadFilesWithArrayOfProjects(arrayOfObject);
      console.log("dashboard.js updateProjectList JSON.stringify(arrayOfProjectFiles.data):", JSON.stringify(arrayOfProjectFiles.data));

      const arrayOfProjectQuotations = await postToGetProjectQuotationsWithArrayOfProjects(
        {
          projectID: projectIDs,
          amount: totalCount || 1,
          page: 1,
        }
      );
      console.log("dashboard.js updateProjectList arrayOfProjectQuotations:", JSON.stringify(arrayOfProjectQuotations));

      const arrayOfQuotationsDetail = await postToGetQuotationsWithArrayOfProjectIDs( {
        projectID: projectIDs
      });

      const arrayOfExpenseTotal = await postToGetVouchersTotalWithArrayOfProjectIDs(
        {
          projectID: projectIDs,
          object: [],
          item: 'amount',
          approvalStatus: [
            'projectManagerVerify',
            'projectDirectorVerify',
            'financeVerify',
            'generalManagerVerifying',
            'generalManagerApproved',
          ],
        }
      );

      for (let i = 0; i < projects.length; i += 1) {
        const project = projects[i];
        const { projectID } = project;
        // const projectData = await postToGetProjectDetail(projectID);
        const projectData = projectsData.data.find(singleProject => singleProject.projectID === projectID);
        // const projectInvoices = await postToGetProjectInvoicesDetail(projectID);
        console.log("dashboard.js updateProjectList JSON.stringify(projectsInvoicesDetail):", JSON.stringify(projectsInvoicesDetail));
        const projectInvoices = projectsInvoicesDetail.data.find(singleProject => singleProject.projectID === projectID);
        // const projectFinanceFiles = await postToGetUploadFiles('projectFinance', projectID);
        const projectFinanceFiles = arrayOfProjectFiles.data?.find(singleProject => singleProject.objectID === projectID);
        console.log("dashboard.js updateProjectList projectFinanceFiles:", JSON.stringify(projectFinanceFiles));
        // const projectQuotations = await postToGetProjectQuotations({
        //   projectID,
        //   amount: 1,
        //   page: 1,
        // });
        var projectQuotations = {
          data: { 
            quotations: arrayOfProjectQuotations.data.quotations?.filter((obj) => {
              if (obj["projectID"] === projectID) {
                  return true;
              }
              return false;
            })
          }
        };

        // const quotationsDetail = await postToGetQuotations({ projectID });
        var quotationsDetail = {
          data: [
            arrayOfQuotationsDetail.data.filter((obj) => {
              if (obj["projectID"] === projectID) {
                  return true;
              }
              return false;
            })
          ]
        };

        // const voucherTotalDetail = await postToGetVouchersTotal({
        //   projectID,
        //   object: [],
        //   item: 'amount',
        //   approvalStatus: [
        //     'projectManagerVerify',
        //     'projectDirectorVerify',
        //     'financeVerify',
        //     'generalManagerVerifying',
        //     'generalManagerApproved',
        //   ],
        // });

        const quotationPriceTotal = quotationsDetail.data
          .map(({ price }) => integerConverter(price))
          .reduce((acc, cur) => acc + cur, 0);
          
        // const voucherPriceTotal = voucherTotalDetail.data.total || 0;
        var voucherPriceTotal = 0;
        const foundExpenseTotal = arrayOfExpenseTotal.data.find(singleProject => singleProject.projectID === projectID);
        if (foundExpenseTotal !== null) {
          voucherPriceTotal = foundExpenseTotal?.total;
        }

        // console.log("dashboard.js updateProjectList projectQuotations:", JSON.stringify(projectQuotations));
        const oopQuotations = projectQuotations.data.quotations
          .filter(quotation => quotation.type === 'oop');

        const oopPriceTotal = oopQuotations
          .map(({ price }) => integerConverter(price || 0))
          .reduce((acc, cur) => acc + cur, 0);

        // const { invoices } = projectInvoices.data;
        // console.log("dashboard.js updateProjectList JSON.stringify(projectInvoices):", JSON.stringify(projectInvoices));
        // console.log("dashboard.js updateProjectList JSON.stringify(projectInvoices.invoices):", JSON.stringify(projectInvoices.invoices));
        const { invoices } = projectInvoices;
        console.log("dashboard.js updateProjectList JSON.stringify(invoices):", JSON.stringify(invoices));
        // const isInvoiced = invoices.length > 0;
        const isInvoiced = (invoices !== undefined && invoices.length > 0);
        // const isPaid = invoices
        //   .some(quotation => quotation.paymentDate !== undefined);
        // console.log("dashboard.js updateProjectList JSON.stringify(invoices):", JSON.stringify(invoices));
        const isPaid = invoices?.some(quotation => quotation.paymentDate !== undefined);

        // const predictDates = invoices
        //   .filter(({ predictDate }) => predictDate !== undefined)
        //   .map(({ predictDate }) => predictDate);
        const predictDates = invoices?.filter(({ predictDate }) => predictDate !== undefined)
          .map(({ predictDate }) => predictDate);

        const predictDate = predictDates?.length > 0
          ? predictDates.shift()
          : null;

        let financeStatus = 'success';

        const isFinanceFail = quotationPriceTotal - oopPriceTotal > agentDifference
          || voucherPriceTotal - oopPriceTotal > agentDifference;
  
        const isFinanceWarning = quotationPriceTotal > oopPriceTotal
          || voucherPriceTotal > oopPriceTotal;
  
        if (isFinanceWarning) { financeStatus = 'warning' }
        if (isFinanceFail) { financeStatus = 'fail' }

        // const unlocked = projectData.data.projectUnlock !== null
        //   && projectData.data.projectUnlock !== undefined
        //   && diffTime(projectData.data.projectUnlock, Date.now(), 'days') <= 1;
        const unlocked = diffTime(projectData.actionTimeStart, Date.now(), 'days') <= 10;

        // const notQuoted = projectQuotations.data.totalCount === undefined
        //   && diffTime(Date.now(), projectData.data.actionTimeEnd, 'days') < 8;
        const notQuoted = projectQuotations.data.totalCount === undefined
          && diffTime(Date.now(), projectData.actionTimeEnd, 'days') < 8;

        // const noPO = projectFinanceFiles.data.length === 0
        //   && diffTime(Date.now(), projectData.data.actionTimeEnd, 'days') < 4;
        const noPO = projectFinanceFiles?.data?.length === 0
          && diffTime(Date.now(), projectData.actionTimeEnd, 'days') < 4;

        project.noPO = noPO;
        project.isPaid = isPaid;
        project.unlocked = unlocked;
        project.notQuoted = notQuoted;
        project.isInvoiced = isInvoiced;
        project.financeStatus = financeStatus;
        project.predictDate = isInvoiced ? predictDate : null;
        // project.actionTimeEnd = projectData.data.actionTimeEnd;
        project.actionTimeEnd = projectData.actionTimeEnd;
        // project.isVerified = projectFinanceFiles?.length > 0;
        project.isVerified = (projectFinanceFiles !== undefined);
        // console.log("dashboard.js updateProjectList projectQuotations.data.itemCount:", projectQuotations.data.itemCount);
        // console.log("dashboard.js updateProjectList projectQuotations.data.quotations.length:", projectQuotations.data.quotations.length);
        project.isQuoted = projectQuotations.data.quotations.length
          && projectQuotations.data.quotations.length > 0;
        if (projectQuotations.data.quotations.length === 0) {
          project.isQuoted = false;
        }
      }

      setProjects([...projects]);
      setProjectCount(totalCount);
      // console.log("dashboard.js " + JSON.stringify(projects));
    } catch (err) { console.log(err) }

    if (responseCount === maxCountForPopupLoading) {
      responseCount = 0;
      setShowLoadingPopup(false);
    }
  };

  // const updateScheduleList = async (page = scheduleCurrentPage) => {
  //   setShowLoadingPopup(true);
  //   console.log("dashboard.js updateScheduleList start");

  //   try {
  //     const { data } = await postToGetProjectTodos(
  //       searchConfigGenerator({
  //         page,
  //         amount,
  //         memberID,
  //         isFinished: false,
  //       }));
  //     const { todos, totalCount } = data;
  //     const projectTodos = todos
  //       .map(todo => ({ ...todo, isChecked: false }));
      
  //     for (let i = 0; i < projectTodos.length; i += 1) {
  //       const { projectID } = projectTodos[i];
  //       const { data } = await postToGetProjectDetail(projectID);
  //       const { projectCode, projectName, companyType } = data;
  //       projectTodos[i].projectCode = projectCode;
  //       projectTodos[i].projectName = projectName;
  //       projectTodos[i].companyType = companyType;
  //     }

  //     projectTodos
  //       .sort((a, b) => a.projectCode > b.projectCode ? 1 : -1);
      
  //     // setSchedules([...projectTodos]);
  //     // setScheduleCount(totalCount);
  //   } catch (err) { console.log(err) }
  //   setShowLoadingPopup(false);
  // };

  const updateExpectedIncome = async () => {
    setShowLoadingPopup(true);
    const expectedIncomeDict = {};

    try {
      const { data } = await postToGetCompanyType({ page: 1, amount: 10 });
      const companyTypesDetail = await postToGetCompanyType({
        page: 1, amount: data.totalCount || 1,
      });

      const companyTypeOptions = companyTypesDetail.data.types
        .map(({ companyTypeID }) => companyTypeID);

      for (let i = 0; i < companyTypeOptions.length; i += 1) {
        const companyType = companyTypeOptions[i];
        const { data } = await postToGetExpectedIncome({
          companyType,
          startDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        });

        expectedIncomeDict[companyType] = data;
      }

      setExpectedIncome({ ...expectedIncomeDict })
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const updateExpectedCost = async () => {
    setShowLoadingPopup(true);
    const expectedCostDict = {};

    try {
      const { data } = await postToGetCompanyType({ page: 1, amount: 10 });
      const companyTypesDetail = await postToGetCompanyType({
        page: 1, amount: data.totalCount || 1,
      });

      const companyTypeOptions = companyTypesDetail.data.types
        .map(({ companyTypeID }) => companyTypeID);

      for (let i = 0; i < companyTypeOptions.length; i += 1) {
        const companyType = companyTypeOptions[i];
        const { data } = await postToGetExpectedCost({
          companyType,
          startDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        });

        expectedCostDict[companyType] = data;
      }

      setExpectedCost({ ...expectedCostDict })
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const fetchApprovedLeaveApplications = async () => {
    try {
      var leaveStatus = "pending,approved";
      const { data } = await getApprovedLeavesByDate(moment().format("YYYY-MM-DD"), leaveStatus);
      setApprovedApplications(data);
    } catch (err) { console.log(err) }
  }

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_TODO_TYPE_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  const initProjectList = useCallback(
    updateProjectList, []);
  
  // const initScheduleList = useCallback(
  //   updateScheduleList, []);

  // const initExpectedCost = useCallback(
  //   updateExpectedIncome, []);

  const initExpectedIncome = useCallback(
    updateExpectedCost, []);

  const initData = useCallback(
    async () => {
      await initProjectList();
      await initExpectedIncome();
  }, [initExpectedIncome, initProjectList]);

  // const initData = useCallback(
  //   async () => {
  //     await initProjectList();
  //     await initScheduleList();
  //     await initExpectedCost();
  //     await initExpectedIncome();
  // }, [initExpectedCost, initExpectedIncome, initProjectList, initScheduleList]);

  useEffect(() => {
    initOptions();
    initData();
  }, [initData, initOptions]);

  useEffect(() => {
    if (companyTypeOptions.length !== 0) {
      const VEDAOption = companyTypeOptions
        .find(option => option.desc === '頤德國際');

      const companyTypeID = VEDAOption
        ? VEDAOption.value
        : companyTypeOptions[0].value;
      setChartCompany(companyTypeID);
    }
  }, [companyTypeOptions]);

  useEffect(() => {
    updateProjectList();
    fetchApprovedLeaveApplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSortConfig]);

  const fetchExpenses = async (page = currentPage) => {
    console.log("dashboard.js fetchExpenses start responseCount:", responseCount);
    if (responseCount === 0) {
      setShowLoadingPopup(true);
    }

    try {
      const { data } = await postToGetExpenses(searchConfigGenerator({
        // ...config,
        amount: 10,
        page,
        user: [userID],
        paymentMethod: [],
        approvalStatus: [
          'projectManagerVerify',
          'projectDirectorVerify',
          'financeVerify',
          'generalManagerVerifying',
          // 'generalManagerApproved',
        ],
        price: {
          min: null,
          max: null,
        },
      }));

      const { expenses, totalCount } = data;
      const expenseForms = [];

      for (let i = 0; i < expenses.length; i += 1) {
        const expense = expenses[i];
        const { projectID, applyDate } = expense;
        const { data } = await postToGetProjectDetail(projectID);
        const { projectCode, projectName } = data;
        expenseForms.push({
          ...expense,
          projectCode,
          projectName,
          applyDate: applyDate === '0001-01-01' ? '' : applyDate,
        });
      };

      setExpenses([...expenseForms]);
      setTotalCount(totalCount || 0);
    } catch(err) { console.log(err) }

    if (responseCount === maxCountForPopupLoading) {
      responseCount = 0;
      setShowLoadingPopup(false);
    }
  };

  const setCurrentPageHandler = page => {
    maxCountForPopupLoading = 1;
    setCurrentPage(page);
    fetchExpenses(page);
  };

  const initExpenses = useCallback(
    fetchExpenses, []);

  useEffect(() => {
    initExpenses();
  }, [initExpenses]);

  // const data = [
  //   { year: '1991', value: 3 },
  //   { year: '1992', value: 4 },
  //   { year: '1993', value: 3.5 },
  //   { year: '1994', value: 5 },
  //   { year: '1995', value: 4.9 },
  //   { year: '1996', value: 6 },
  //   { year: '1997', value: 7 },
  //   { year: '1998', value: 9 },
  //   { year: '1999', value: 13 },
  // ];

  // const config = {
  //   data,
  //   xField: 'year',
  //   yField: 'value',
  //   point: {
  //     size: 5,
  //     shape: 'diamond',
  //   },
  // };

  // let chart;

  return (
    <MainContent>
      <DivTableWrapper>
        <TableApprovedLeavedApplications
          approvedApplications = { approvedApplications }
        />
      </DivTableWrapper>
      <DivTableWrapper>
        <TableProjects
          mode = { displayMode }
          projects = { projects }
          showProject = { showProject }
          showDetailButton = { true }
          totalCount = { projectCount }
          currentPage = { projectCurrentPage }
          projectCodeFilter = { projectCodeFilter }
          searchHandler = { searchHandler }
          pageChangedHandler = { setProjectPageHandler }
          goProjectDetailHandler = { goProjectDetailHandler }
          modeChangeHandler = { updateDisplayModeHandler }
          projectCodeFilterChangeHandler = { projectCodeFilterChangeHandler }

          getTableSortIcon = { getTableSortIcon }
          tableSortConfig = { tableSortConfig }
          tableSortHandler = { tableSortHandler }
        />
      </DivTableWrapper>
      <DivTableWrapper>
        <TableExpenseFormList
          expenses = { expenses }
          totalCount = { totalCount }
          currentPage = { currentPage }
          pageChangedHandler = { value => {
            setCurrentPageHandler(value);
          }}
          goDetailHandler = { id => {
            history.push(`/Expense/ExpenseFormDetail/${id}`)
          }}
        />
      </DivTableWrapper>
      {/* <div>
        <Line {...config} />
      </div> */}
      {/*  
      <DivTableWrapper>
        <TableSchedules
          showCheckbox = { false }
          showDetailButton = { true }
          schedules = { schedules }
          totalCount = { scheduleCount }
          currentPage = { scheduleCurrentPage }
          pageChangedHandler = { setSchedulePageHandler }
          goScheduleDetailHandler = { goScheduleDetailHandler }
        />
      </DivTableWrapper>
      <DivTableWrapper>
        <TableChart
          mode = { chartMode }
          company = { chartCompany }
          chartData = { chartData }
          modeChangeHandler = { setChartMode }
          companyChangeHandler = { setChartCompany }
        />
      </DivTableWrapper>
      */}
      { showLoadingPopup && <PopupLoading/> }
      {
        showAlertPopup &&
          <PopupExecuteResult
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
    </MainContent>
  );
};

export default Dashboard;
