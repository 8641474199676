import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: ${ props => '640px'
  };
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead
    >
      <TableRow>
        <Th120>人員</Th120>
        <Th120>假別</Th120>
        <Th160>休假開始時間</Th160>
        <Th160>休假結束時間</Th160>
      </TableRow>
    </THead>
  );
};

export default Header;
