import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';
import moment from 'moment';

import { getOptionDesc } from '@/utils/textUtils';

import { decimalCommaConverter, integerConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { overtime } = props;
  console.log(`TableSalaryOvertimeList Item.js JSON.stringify(overtime):${JSON.stringify(overtime)}`);
  // const { user, objectID } = expense;
  // const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  // const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  // const username = getOptionDesc(memberOptions, user);

  // const objectName = expense.object === 'user'
  //   ? getOptionDesc(memberOptions, objectID)
  //   : getOptionDesc(vendorIDs, objectID);

  return (
    <TrWrapper>
      <Td120>{ overtime.date }</Td120>
      <Td120>{ overtime.startTime == null ? '' : 
        `${moment.parseZone(overtime.startTime).format('HH:mm')}`}
      </Td120>
      <Td120>{ overtime.endTime == null ? '' : 
        `${moment.parseZone(overtime.endTime).format('HH:mm')}`}
      </Td120>
      <Td120>{ overtime.durationInSeconds == null ? '' : Math.round(overtime.durationInSeconds / 3600 * 100) / 100 }</Td120>
      <Td120>{ overtime.hourlySalary == null ? '' : decimalCommaConverter(overtime.hourlySalary) }</Td120>
      <Td120>{ overtime.salary == null ? '' : decimalCommaConverter(overtime.salary) }</Td120>
      <Td240>{ overtime.explanation }</Td240>
    </TrWrapper>
  );
};

export default Item;
