import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import {
  getOptionDesc,
  getOvertimeApprovementStatus,
  getOvertimeDateTypeString,
} from '@/utils/textUtils';

import {
  // diffTime,
  // diffTimeWithFloat,
  diffTimeSubtractLunchBreak,
  // formatTime,
} from '@/utils/timeUtils';

import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1480px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { overtimeRecord } = props;
  const { startTimeLocal, endTimeLocal } = overtimeRecord;
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);

  const workingHoursType = getOptionDesc(workingHoursTypeOptions, overtimeRecord.typeID);

  const type = overtimeType => {
    if (overtimeType === '') return '';

    return overtimeType === 'freeTime'
      ? '補休'
      : '加班費';
  };

  const approvedMinutes = () => {
    if (overtimeRecord.approvementStatus !== 'approved') return 0;

    return overtimeRecord.overtimeType === 'freeTime'
      ? (overtimeRecord.freeTimeMins / 60).toFixed(1)
      : (overtimeRecord.extraPayMins / 60).toFixed(1);
  };

  return (
    <TrWrapper>
      <Td120>{ overtimeRecord.username }</Td120>
      <Td120>{ overtimeRecord.date }</Td120>
      <Td160>{ overtimeRecord.projectCode }</Td160>
      <Td160>{ overtimeRecord.projectName }</Td160>
      <Td120>{ workingHoursType }</Td120>
      <Td120>{ overtimeRecord.tag }</Td120>
      <Td240>
        {
          `${moment.parseZone(startTimeLocal).format('MM/DD HH:mm')} ~ ${moment.parseZone(endTimeLocal).format('MM/DD HH:mm')}`
        }
      </Td240>
      <Td80>{ diffTimeSubtractLunchBreak(startTimeLocal, endTimeLocal, 'hours') }</Td80>
      <Td120>
        {
          `${moment.parseZone(overtimeRecord.submitDate).format('YYYY/MM/DD HH:mm:ss')}`
        }
      </Td120>
      <Td80>
        {
          getOvertimeApprovementStatus(overtimeRecord.approvementStatus)
        }
      </Td80>
      <Td80>{ type(overtimeRecord.overtimeType) }</Td80>
      <Td80>{ getOvertimeDateTypeString(overtimeRecord.overtimeDateType) }</Td80>
      <Td120>{ approvedMinutes() }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { props.goDetailHandler }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
