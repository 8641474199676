import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import {
  deleteUser,
  putModifyUser,
  postToGetUserInfo,
  getUserHistoryById,
  patchUpdateUserHistory,
} from '@/api/authService';

import {
  getLaborHealthInsuranceSettings,
  getPaycheckStructures,
  getExtraPayments,
  deleteExtraPayments,
} from '@/api/financeService'

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_DIRECTOR_OPTIONS,
  FETCH_DEPARTMENT_OPTIONS,
  FETCH_SYSTEM_ROLE_OPTIONS,
  FETCH_COMPANY_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelUserDetailSalary from '@/components/Panel/Authority/PanelUserDetailSalary';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { searchConfigGenerator } from '@/utils/apiRequestUtils';
import { userConfig, userHistoryConfig } from '@/constant/dataConfig/authorityModule';
import PopupAddOrEditWageStructure from '@/components/UI/Popup/PopupAddOrEditWageStructure';
import PopupSalaryExtraPayment from '@/components/UI/Popup/PopupSalaryExtraPayment';
import PopupViewWageStructure from '@/components/UI/Popup/PopupViewWageStructure';

import { integerConverter } from '@/utils/textUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding-right: 0;
  padding-left: 135px;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;
  margin-bottom: 10px;
`;

const UserDetailSalary = props => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const authorityRoutes = useSelector(state => state.login.authorityRoutes);
  const directorOptions = useSelector(state => state.srpOptions.directorOptions);
  const systemRoleOptions = useSelector(state => state.srpOptions.systemRoleOptions);
  const departmentOptions = useSelector(state => state.srpOptions.departmentOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [user, setUser] = useState(cloneDeep(userConfig));

  const [userHistory, setUserHistory] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showDeleteSuccessPopup, setShowDeleteSuccessPopup] = useState(false);

  const [showPopupAddOrEditWageStructure, setShowPopupAddOrEditWageStructure] = useState(false);
  const [paycheckStructures, setPaycheckStructures] = useState([]);
  const [modifyPaycheckStructureId, setModifyPaycheckStructureId] = useState();
  const [viewPaycheckStructureId, setViewPaycheckStructureId] = useState("");
  const [showPopupViewWageStructure, setShowPopupViewWageStructure] = useState(false);
  // const [editExtraPaymentId, setEditExtraPaymentId] = useState("");

  const [showDeleteExtraPaymentSuccessPopup, setShowDeleteExtraPaymentSuccessPopup] = useState(false);
  const [showDeleteExtraPaymentPopup, setShowDeleteExtraPaymentPopup] = useState(false);
  const [deleteExtraPaymentId, setDeleteExtraPaymentId] = useState(false);
  const [extraPaymentCurrentPage, setExtraPaymentCurrentPage] = useState(1);
  const [extraPaymentTotalCount, setExtraPaymentTotalCount] = useState(0);
  const [extraPayments, setExtraPayments] = useState([]);
  const [showPopupSalaryExtraPayment, setShowPopupSalaryExtraPayment] = useState(false);

  const userEditable = authorityRoutes.indexOf('UserDetailSalary-Edit') !== -1;
  const userDeletable = authorityRoutes.indexOf('UserDetailSalary-Delete') !== -1;

  const unsavable = user.email === ''
  || user.name === '';

  const updateUserHandler = (key, value) => {
    const updateObj = cloneDeep(user);
    if (key === "basicSalary") {
      updateObj[key] = integerConverter(value);
    } else {
      updateObj[key] = value;
    }
    // console.log("userDetai.js updateUserHandler JSON.stringify(updateObj):" + JSON.stringify(updateObj));
    setUser({...updateObj});
  };

  const updateUserHistoryHandler = (key, value) => {
    const updateObj = cloneDeep(userHistory);
    updateObj[0][key] = value;//can only update the first entry
    // console.log("userDetai.js updateUserHistoryHandler JSON.stringify(updateObj):" + JSON.stringify(updateObj));
    setUserHistory(updateObj);
  };

  const closeSuccessPopupHandler = () => {
    setIsEditing(false);
    setShowSaveSuccessPopup(false);
  };

  const deleteUserHandler = async () => {
    setShowDeletePopup(false);
    setShowLoadingPopup(true);

    try {
      await deleteUser(id);
      setShowLoadingPopup(false);
      setShowDeleteSuccessPopup(true);
    } catch(err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
    }
  };

  const updateUserData = async () => {
    console.log(`userDetai.js updateUserData()`);
    setShowLoadingPopup(true);

    try {
      await putModifyUser(searchConfigGenerator(user));
      fetchUser();
      //save offdate is empty
      var editingUserHistoryId = userHistory[0].id;
      var editingUserHistoryUserId = userHistory[0].userID;
      var editingUserHistory = cloneDeep(userHistory[0]);
      delete editingUserHistory.id;
      delete editingUserHistory.userID;
      console.log(`userDetai.js updateUserData() JSON.stringify(editingUserHistory)=${JSON.stringify(editingUserHistory)}`);
      await patchUpdateUserHistory(editingUserHistoryUserId, editingUserHistoryId, editingUserHistory);
      //save offdate is empty
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '更新失敗', err }));
      console.log(err);
    }
  };

  const fetchUser = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetUserInfo(id);
      console.log("userDetail.js fetchUser() JSON.stringify(data):" + JSON.stringify(data));
      if (data["enable"] === undefined) {
        data["enable"] = false;
      }
      setUser({
        ...cloneDeep(userConfig),
        ...data,
      });

      const dataUserHistory = await getUserHistoryById(id, "onboardDate_desc");
      console.log("userDetail.js fetchUser() JSON.stringify(dataUserHistory):" + JSON.stringify(dataUserHistory.data.history));
      setUserHistory(dataUserHistory.data.history);

      const dataLaborHealthInsurance = await getLaborHealthInsuranceSettings();
      // console.log("userDetail.js fetchUser() JSON.stringify(dataLaborHealthInsurance):" + JSON.stringify(dataLaborHealthInsurance));
      const laobrHealthInsuranceGrade = dataLaborHealthInsurance.data.settings.map((element) => {
        return {"desc": element.insuranceGrade, "value": element.insuranceGrade};
      });

      console.log("userDetail.js fetchUser() JSON.stringify(laobrHealthInsuranceGrade):" + JSON.stringify(laobrHealthInsuranceGrade));

    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initUser = useCallback(fetchUser, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_DIRECTOR_OPTIONS());
    dispatch(FETCH_DEPARTMENT_OPTIONS());
    dispatch(FETCH_SYSTEM_ROLE_OPTIONS());
    dispatch(FETCH_COMPANY_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initUser();
    initOptions();
  }, [initOptions, initUser])

  const addSalaryExtraPaymentHandler = () => {
    // setEditExtraPaymentId("");
    setShowPopupSalaryExtraPayment(true);
  }

  // const editExtraPaymentHandler = (extraPaymentId) => {
  //   console.log(`userDetailSalary.js editExtraPaymentHandler() extraPaymentId=${extraPaymentId}`);
  //   setShowPopupSalaryExtraPayment(true);
  //   setEditExtraPaymentId(extraPaymentId);
  // }

  const removeExtraPaymentHandler = (extraPaymentId) => {
    console.log(`userDetailSalary.js removeExtraPaymentHandler() extraPaymentId=${extraPaymentId}`);
    setDeleteExtraPaymentId(extraPaymentId);
    setShowDeleteExtraPaymentPopup(true);
  }

  const deleteExtraPaymentHandler = async () => {
    console.log(`userDetailSalary.js deleteExtraPaymentHandler() deleteExtraPaymentId=${deleteExtraPaymentId}`);
    try {
      await deleteExtraPayments(deleteExtraPaymentId);
      setShowLoadingPopup(false);
      setShowDeleteExtraPaymentSuccessPopup(true);
    } catch (err) { 
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '刪除失敗', err }));
      console.log(err);
     }
  }

  const addPaycheckStructureHandler = () => {
    setModifyPaycheckStructureId("");
    setShowPopupAddOrEditWageStructure(true);
  }

  const editPaycheckStructureHandler = (paycheckStructureId) => {
    console.log(`userDetailSalary.js editPaycheckStructureHandler() paycheckStructureId=${paycheckStructureId}`);
    setModifyPaycheckStructureId(paycheckStructureId);
    setShowPopupAddOrEditWageStructure(true);
  }

  const cancelHandler = () => {
    setShowPopupViewWageStructure(false);
  }

  const viewPaycheckStructureHandler = (paycheckStructureId) => {
    console.log(`myInfoSetting.js viewPaycheckStructureHandler paycheckStructureId=${paycheckStructureId}`);
    setViewPaycheckStructureId(paycheckStructureId);
    setShowPopupViewWageStructure(true);
  }

  const fetchPaycheckStructures = async () => {
    setShowLoadingPopup(true);

    try {
      console.log(`userDetailSalary.js fetchPaycheckStructures() id=${id}`);
      const searchParameters = {
        userID: id,
        orderBy: "applyDate_desc",
      }
      const { data } = await getPaycheckStructures(searchParameters);
      setPaycheckStructures(data.paycheckStructures);
      console.log(`userDetailSalary.js fetchPaycheckStructures() data=${JSON.stringify(data)}`);
      const tempPaycheckStructures = data.paycheckStructures.filter((element) => {
        return element.userId === id;
      });
      console.log("userDetailSalary.js fetchPaycheckStructures() tempPaycheckStructures:" + JSON.stringify(tempPaycheckStructures));
      setPaycheckStructures(tempPaycheckStructures);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const reloadPaycheckStructuresHandler = () => {
    console.log(`userDetailSalary.js reloadPaycheckStructuresHandler()`);
    setShowPopupAddOrEditWageStructure(false);
    fetchPaycheckStructures();
  };

  const reloadExtraPaymentsHandler = () => {
    console.log(`userDetailSalary.js reloadExtraPaymentsHandler()`);
    setShowPopupSalaryExtraPayment(false);
    fetchExtraPayments();
  };

  const extraPaymentPageChangedHandler = page => {
    console.log(`userDetailSalary.js extraPaymentPageChangedHandler()`);
    setExtraPaymentCurrentPage(page);
    fetchExtraPayments(page);
  }

  const fetchExtraPayments = async (page = extraPaymentCurrentPage) => {
    setShowLoadingPopup(true);
    try {
      console.log(`userDetailSalary.js fetchExtraPayments() JSON.stringify(id)=${JSON.stringify(id)}&page=${page}`);
      const searchParameters = {
        userId: id,
        page: page,
        amount: 10,
      }
      const { data } = await getExtraPayments(searchParameters);
      setExtraPayments(data.extraPayments);
      setExtraPaymentTotalCount(data.totalCount);
      // console.log(`userDetailSalary.js fetchExtraPayments() data=${JSON.stringify(data)}`);
      // const tempExtraPayments = data.extraPayments.filter((extraPayment) => {
      //   console.log(`userDetailSalary.js fetchExtraPayments() JSON.stringify(extraPayment)=${JSON.stringify(extraPayment)}&id=${id}`);
      //   var bMatchingUserId = false;
      //   extraPayment.recipients.forEach(function (recipient) {
      //     if (recipient.userId === id) {
      //       bMatchingUserId = true;
      //     }
      //   });
      //   if (bMatchingUserId) {
      //     return true;
      //   } else {
      //     return true;
      //   }
      // });
      // console.log(`userDetailSalary.js fetchExtraPayments() tempExtraPayments=${JSON.stringify(tempExtraPayments)}&tempExtraPayments.length=${tempExtraPayments.length}`);
      // setExtraPayments(tempExtraPayments);
      // setExtraPaymentTotalCount(tempExtraPayments.length);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const updateData = async () => {
    console.log(`userDetailSalary.js updateData() id=${JSON.stringify(id)}`);
    fetchPaycheckStructures();
    fetchExtraPayments();
  };

  const initData = useCallback(
    updateData
  , []
  );

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <MainContent>
      <PanelUserDetailSalary
        title = "使用者薪資資料"
        user = { user }
        disabled = { !isEditing }
        disableAccount = { true }
        disablePassword = { true }
        directorOptions = { directorOptions }
        departmentOptions = { departmentOptions }
        systemRoleOptions = { systemRoleOptions }
        companyTypeOptions = { companyTypeOptions }
        updateUserHandler = { updateUserHandler }
        userHistory = { userHistory }
        updateUserHistoryHandler = { updateUserHistoryHandler }
        paycheckStructures = { paycheckStructures }
        addPaycheckStructureHandler = { addPaycheckStructureHandler }
        editPaycheckStructureHandler = { editPaycheckStructureHandler }
        viewPaycheckStructureHandler = { viewPaycheckStructureHandler }

        extraPayments = { extraPayments }
        extraPaymentTotalCount = { extraPaymentTotalCount }
        extraPaymentCurrentPage = { extraPaymentCurrentPage }
        addSalaryExtraPaymentHandler = { addSalaryExtraPaymentHandler }
        // editExtraPaymentId = { editExtraPaymentId }
        // editExtraPaymentHandler = { editExtraPaymentHandler }
        removeExtraPaymentHandler = { removeExtraPaymentHandler }
        extraPaymentPageChangedHandler = { extraPaymentPageChangedHandler }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            {
              isEditing
                ? <ButtonSave
                    disabled = { unsavable }
                    onClick = { updateUserData }
                  >儲存</ButtonSave>
                : <ButtonSave
                    disabled = { !userEditable }
                    onClick = { () => { setIsEditing(true) } }
                  >編輯</ButtonSave>
            }
            <ButtonSave
              disabled = { !userDeletable }
              onClick = { () => {
                setShowDeletePopup(true);
              }}
            >刪除</ButtonSave>
            <ButtonClear
              onClick = { () => { history.goBack() } }
            >離開</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelUserDetailSalary>
      { showLoadingPopup && <PopupLoading/> }
      {
        showDeletePopup &&
          <PopupExecuteConfirm
            title = "刪除使用者"
            text = "確定刪除此使用者"
            confirmHandler = { deleteUserHandler }
            cancelHandler = { () => {
              setShowDeletePopup(false);
            }}
          />
      }
      {
        showDeleteSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              history.goBack();
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "更新成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      { 
        showPopupAddOrEditWageStructure &&
          <PopupAddOrEditWageStructure
            editUserId = { id }
            modifyPaycheckStructureId = { modifyPaycheckStructureId }
            reloadPaycheckStructuresHandler = { reloadPaycheckStructuresHandler }
            cancelHandler = { () => {
              setShowPopupAddOrEditWageStructure(false);
            } }
            // reloadHandler = { reloadHandler }
          />
      }
      { showPopupViewWageStructure && 
          <PopupViewWageStructure
            viewPaycheckStructureId = { viewPaycheckStructureId }
            cancelHandler = {() => {
              cancelHandler();
            }}
          /> 
      }
      { 
        showPopupSalaryExtraPayment &&
          <PopupSalaryExtraPayment
            editUserId = { id }
            modifyPaycheckStructureId = { modifyPaycheckStructureId }
            // editExtraPaymentId = { editExtraPaymentId }
            reloadExtraPaymentsHandler = { reloadExtraPaymentsHandler }
            // saveExtraPaymentHandler = { saveExtraPaymentHandler }
            // extraPaymentsParams = { extraPaymentsParams }
            // updateExtraPaymentsParamsHandler = { updateExtraPaymentsParamsHandler }
            cancelHandler = { () => {
              setShowPopupSalaryExtraPayment(false);
            } }
            // reloadHandler = { reloadHandler }
          />
      }
      {
        showDeleteExtraPaymentPopup &&
          <PopupExecuteConfirm
            title = "刪除此薪資加、減項"
            text = "確定刪除此刪除此薪資加、減項"
            confirmHandler = { () => {
              setShowDeleteExtraPaymentPopup(false);
              deleteExtraPaymentHandler();
            }}
            cancelHandler = { () => {
              setShowDeleteExtraPaymentPopup(false);
            }}
          />
      }
      {
        showDeleteExtraPaymentSuccessPopup &&
          <PopupExecuteResult
            title = "刪除成功"
            confirmHandler = { () => {
              setShowDeleteExtraPaymentSuccessPopup(false);
              reloadExtraPaymentsHandler();
            }}
          />
      }
    </MainContent>
  );
};

export default UserDetailSalary;
