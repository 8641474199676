import React from 'react';
import { useSelector } from 'react-redux';

import TableWrapper  from '@/components/UI/TableWrapper';
import PageSelector from '@/components/UI/PageSelector';
import { Table, TableBody } from '@/component-style/Table';
import Header from './Header';
import Item from './Item';

const TableApprovedLeavedApplications = props => {
  const { approvedApplications } = props;
  const expenseRoutes = useSelector(state => state.login.expenseRoutes);
  const disableDetailPage = expenseRoutes.indexOf('ExpenseFormDetail') === -1;

  return (
    <TableWrapper title = "今日休假人員列表">
      <Table>
        <Header
        />
        <TableBody>
          {
            approvedApplications.map(approvedApplication =>
              <Item
                key = { approvedApplication.userId }
                approvedApplication = { approvedApplication }
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        current = { props.currentPage }
        total = { props.totalCount }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </TableWrapper>
  );
};

export default TableApprovedLeavedApplications;
