import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';
import { expenseApprovalStates } from '@/constant/options';

import { decimalCommaConverter } from '@/utils/textUtils';
import moment from 'moment';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: ${ props => '640px'
  };

  &:hover {
    background: #D3D3D3;
    cursor: pointer;
  };

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { approvedApplication } = props;
  
  return (
    <TrWrapper>
      <Td120>{ approvedApplication.username }</Td120>
      <Td120>{ approvedApplication.leaveInfo[0].name }</Td120>
      <Td160>{ moment.utc(approvedApplication.leaveInfo[0].from).format('YYYY-MM-DD HH:mm:ss') }</Td160>
      <Td160>{ moment.utc(approvedApplication.leaveInfo[0].to).format('YYYY-MM-DD HH:mm:ss') }</Td160 >
    </TrWrapper>
  );
};

export default Item;
