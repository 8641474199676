import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import PanelWrapper from '@/components/UI/PanelWrapper';
import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import SearchButtonSet from '@/components/UI/SearchButtonSet';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';
import { PSubtitle } from '@/component-style/TextElement';
import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

import { vendorPayments } from '@/constant/options';
import { formatTime } from '@/utils/timeUtils';
import SelectNormal from '@/components/UI/Select/SelectNormal';

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const ButtonExport = styled(ButtonBasic)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonAdd = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const PanelPersonnelWageSearch = props => {
  const financeRoutes = useSelector(state => state.login.financeRoutes);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);
  const { config } = props;

  return (
    <PanelWrapper title = "同仁月薪資">
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>薪資月份</PSubtitle>
          <DateSelectorTwo
            value = { config.wageMonth  }
            picker = "month"
            changeHandler = { value => {
              props.updateConfigHandler(
                'wageMonth',
                formatTime(value, 'YYYY-MM')
              );
            }}
          />
          &nbsp;&nbsp;
          <PSubtitle>於 { config.payMonth } 月發放</PSubtitle>
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivItemWrapper>
          <PSubtitle>所屬公司</PSubtitle>
          <SelectNormal
            disabled = { props.disabled }
            value = { config.companyType }
            options = {[
              { value: '', desc: '請選擇公司' },
              ...companyTypeOptions,
            ]}
            changeHandler = { value => {
              props.updateConfigHandler('companyType', value);
            }}
          />
        </DivItemWrapper>
      </DivFlexRow>
      <DivFlexRow>
        <DivButtonsWrapper>
          <div>&nbsp;</div>
          <div>
            <ButtonAdd
              onClick = { () => { props.exportSalaryListTxtHandler(); } }
            >
              匯出同仁薪資txt
            </ButtonAdd>
            <ButtonAdd
              onClick = { () => { props.exportSalaryListXLSHandler(); } }
            >
              匯出同仁薪資xls
            </ButtonAdd>
            <ButtonAdd
              // disabled = { !props.enableCalculate }
              disabled = { false }
              onClick = { () => { props.calculateWageHandler(); } }
            >計算</ButtonAdd>
            <ButtonAdd
              onClick = { () => { props.searchWageHandler(); } }
            >查詢</ButtonAdd>
          </div>
        </DivButtonsWrapper>
      </DivFlexRow>
      {/* <DivFlexRow>
        <DivButtonsWrapper>
          <ButtonExport
            disabled = { !userExportable }
            onClick = { props.exportHandler }
          >匯出</ButtonExport>
          <SearchButtonSet
            clearHandler = { props.clearHandler }
            searchHandler = { props.searchHandler }
          />
        </DivButtonsWrapper>
      </DivFlexRow> */}
    </PanelWrapper>
  );
};

export default PanelPersonnelWageSearch;
