import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import ErrorAlert from '@/components/UI/ErrorAlert';
import ButtonLoginBy365 from '@/components/UI/ButtonLoginBy365';
import { InputBasic } from '@/component-style/Input';
import { ButtonBasic } from '@/component-style/Button';

const DivLogin = styled.div`
  width: 360px;

  @media (max-width: 768px) {
    padding: 0 30px;
    box-sizing: border-box;
  }

  @media (max-width: 320px) {
    width: 320px;
  }
`;

const H3Title = styled.h3`
  font-size: 24px;
  font-weight: 300;
  color: #57585a;
  margin-bottom: 15px;
`;

const LinkElement = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  height: 14px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.4px;
  text-align: center;
  color: #e45050;
`;

const PLoginMethodSpacer = styled.p`
  margin: 15px;
  color: #8B8B8B;
  text-align: center;
  font-weight: lighter;
  font-size: 16px;
`;

const InputLogin = styled(InputBasic)`
  width: 100%;
  border: solid 2px #f8f8f8;
  margin-bottom: 20px;

  @media (min-width: 1920px) {
    width: 100%;
  }
`;

const ButtonSignIn = styled(ButtonBasic)`
  height: 50px;
`;

const LoginForm = props => {

  const submitHandler = async (event) => {
    event.preventDefault();
    // const reCaptchaToken = await props.googleReCaptchaProps
    //   .executeRecaptcha('login');
    props.login("6LcLj_UUAAAAAEPkQiazEs_toldZRG6hf0QA9Imh");
  };

  return (
    <DivLogin>
      <H3Title>Sign in to Veda</H3Title>
      {
        props.showError &&
        <ErrorAlert
          errorMsg = { props.errorMsg }
        />
      }
      <ButtonLoginBy365
        loginHandler = { props.loginByAzureHandler }
      />
      <PLoginMethodSpacer>OR</PLoginMethodSpacer>
      <InputLogin
        name="id"
        type="text"
        placeholder="User Account"
        value = { props.account }
        onChange = { event => props.setAccount(event.target.value) }
      />
      <InputLogin
        type="password"
        name="password"
        placeholder="Password"
        autocomplete = { false }
        value = { props.password }
        onChange = { event => props.setPassword(event.target.value) }
      />
      <ButtonSignIn
        onClick = { submitHandler }
      >Sign in</ButtonSignIn>
      <LinkElement
        to="/ResetPassword"
      >Forgot Password?
      </LinkElement>
    </DivLogin>
  )
};

export default withGoogleReCaptcha(LoginForm);
