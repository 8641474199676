import React from 'react';
import styled from 'styled-components';

import { Table, TableBody } from '@/component-style/Table';
import { ButtonBasic } from '@/component-style/Button';
import PageSelector from '@/components/UI/PageSelector';
import Header from './Header';
import Item from './Item';

const DivWrapper = styled.div`
  width: 100%;
  border-top: 2px solid #de2027;
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const H3Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  color: #57585a;
`;

const ButtonAgreeAll = styled(ButtonBasic)`
  width: 120px;
  height: 40px;
`;

const TableOvertimeApprovements = props => {
  const { overtimeRecords } = props;

  return (
    <DivWrapper>
      <TitleWrapper>
        <H3Title>加班審核列表</H3Title>
        <ButtonAgreeAll
          onClick = { props.agreeAllHandler }
        >全部同意共{props.totalCount}筆</ButtonAgreeAll>
      </TitleWrapper>
      <Table>
        <Header/>
        <TableBody>
          {
            overtimeRecords.map((overtimeRecord, index) =>
              <Item
                key = { overtimeRecord.workingHoursID }
                overtimeRecord = { overtimeRecord }
                agreeHandler = {() => { props.agreeHandler(index)}}
                disagreeHandler = {() => { props.disagreeHandler(index)}}
                updateValidTimeHandler = { value => { props.updateValidTimeHandler(index, value)}}
              />
            )
          }
        </TableBody>
      </Table>
      <PageSelector
        total = { props.totalCount }
        current = { props.currentPage }
        pageChangedHandler = { props.pageChangedHandler }
      />
    </DivWrapper>
  );
};

export default TableOvertimeApprovements;
